import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { navigate, useLocation } from '@reach/router';
import { updateUser, loginUser } from '@store/user/actions';
import { makePostRequest } from '@helpers/requests';
import { AUTH_EMAIL_CHANGE_COMPLETE } from '@helpers/api';
import { getUserFromToken } from '@helpers/auth';
import Loader from '@components/common/Loader';
import { useSnackbar } from '@components/common/Snackbar';
import { UserState } from '@store/user/types';

const CompleteChangeEmail = () => {
	const [openSnackbar] = useSnackbar();
	const location = useLocation();
	const params = queryString.parse(location.search);
	const code = params?.code;
	const userId = params?.id;
	const email = params?.email;
	const user = useSelector(({ user }) => user);
	const dispatch = useDispatch();

	useEffect(() => {
		if (!code || !userId || !email) {
			navigate('/dashboard/account');
			return;
		}

		(async () => {
			try {
				const { data: tokens } = await makePostRequest(AUTH_EMAIL_CHANGE_COMPLETE, {
					code,
					id: userId,
					currentEmail: email,
				});

				if (user) {
					// gets user obj from new tokens, run updateUser or loginUser passing the user and tokens
					const data = await getUserFromToken(tokens);
					dispatch(updateUser());
				} else {
					dispatch(loginUser(tokens));
				}

				navigate('/dashboard/account');
				openSnackbar('Successfully changed email address.');
			} catch (error) {
				if (error !== 'cancelled') {
					openSnackbar(
						error?.errorMessage ??
							'An error occurred when attempting to change your email.',
						'error'
					);
					navigate('/');
				}
			}
		})();
	}, []);

	return <Loader tip="Changing your email address..." />;
};

export default CompleteChangeEmail;
